div.MuiButtonBase-root.MuiAccordionSummary-root{
    background-color: rgba(0, 0, 0, .03);
}
div.MuiButtonBase-root.MuiAccordionSummary-root div p {
    font-weight: 500;
}

a.MuiButtonBase-root span.MuiButton-label i.contact-icons {
    font-size: 30px;
}

.MuiSnackbarContent-root {
    background-color: #f44336 !important;    
}

.MuiSnackbarContent-root div {
    font-weight: bolder !important;
}

#success-message .MuiSnackbarContent-root {
    background-color:#1769aa !important
}