.mainImages {
    width: 200;
    height: 200px;
    /* box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
    border: 2px solid white;
    text-align: center;
}
a.image-service{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

/* .makeStyles-container-1.lexbufete-text {
    margin: 0 0 0 12rem;
    padding: 0;
} */

.alert {
    position: absolute;
}

.hide {
    display: none;
}

div.images-container {
    position: absolute;
    top: -12.4rem;
    left: 0.6%
}
a.image-service button{
    display: inline-flex;
    position: absolute;
    color: white !important;
    font-weight: 800;
    /* letter-spacing: 1.5px; */
    text-shadow: 0px 1px 4px black;
}

.container-text {
    display: block;
    width: 100vw;
    text-align: center;
    margin-top: -250px;
    /* background-color: red; */
}

.container-text div {
    /* background-color: green; */
    text-align: center;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
}

/* .lb-brief-icon::before {
    content: url('../../assets/img/little.png');
    position: absolute;
    left: 50%;
    top: -30px;
    z-index: 4;
} */

/* Home Image Break Point */
@media only screen and (max-width: 635px)  {
    .mainImages {        
        max-width: 60vw;
    }
}
@media only screen and (max-width: 320px)  {
    .mainImages {        
        max-width: 30vw;
    }
}